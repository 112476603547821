.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgb(255 255 255 / 75%);
  z-index: 9;
}

.center {
  position: relative;
  height: 100vh;
}

.center .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inner {
  max-width: 450px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.loaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #37b7e3;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
}

.process h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 20px;
  letter-spacing: 5px;
  color: rgb(0 0 0 / 50%);
}

.process .btn {
  margin-top: 30px;
  padding: 8px 30px;
  border-radius: 2px;
  background: #37b7e3;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
}

.process .btn:hover {
  letter-spacing: 4px;
  color: #fff;
}
